import { RecurringType } from "../enums/recurring-type";
import { EventRepeatType } from "../enums/event-repeat-type";
import { EventReminderType } from "../enums/event-reminder-type";
import { EventCalendarType } from "../enums/event-calendar-type";
import { InvoiceAccountingType } from "../enums/invoice-accounting-type";
import { SignicatSignStatus } from "../enums/signicat-sign-status";
import { MarketGroup } from "../enums/market-product";
import { ToDoStatus, ToDoPriority } from "../enums/todo";
import { ShopPlatform } from "../enums/shop-platform";
import { WidgetKeys } from "../enums/widget-keys";

export const EnumHelper = {
    getRecurringTypeDDL,

    getEventRepeatTypeDDL,
    getEventReminderTypeDDL,
    getEventCalendarTypeDDL,

    getInvoiceAccountingTypeDDL,

    getDigitalSigningStatusDDL,

    getMarketGroupDDL,

    getToDoStatusDDL,
    getToDoPriorityDDL,

    getShopPlatformDDL,
    getWidgetDDL
}

function getRecurringTypeDDL() {
    const recurKeys = Object.keys(RecurringType)
    const arr = []

    for (const item of recurKeys) {
        const tmp = {
            value: RecurringType[item],
            label: item,
            tkey: `RecurringType-${item}`
        }

        arr.push(tmp)
    }

    return arr
}

function getEventRepeatTypeDDL() {
    const arrKeys = Object.keys(EventRepeatType)
    const arr = []

    for (const item of arrKeys) {
        const tmp = {
            value: EventRepeatType[item],
            label: item,
            tkey: `EventRepeatType-${item}`
        }

        arr.push(tmp)
    }

    return arr
}

function getEventReminderTypeDDL() {
    const arrKeys = Object.keys(EventReminderType)
    const arr = []

    for (const item of arrKeys) {
        const tmp = {
            value: EventReminderType[item],
            label: item,
            tkey: `EventReminderType-${item}`
        }

        arr.push(tmp)
    }

    return arr
}

function getEventCalendarTypeDDL() {
    const arrKeys = Object.keys(EventCalendarType)
    const arr = []

    for (const item of arrKeys) {
        const tmp = {
            value: EventCalendarType[item],
            label: item,
            tkey: `calendar-type-${item}`
        }

        arr.push(tmp)
    }

    return arr
}

function getInvoiceAccountingTypeDDL() {
    const arrKeys = Object.keys(InvoiceAccountingType);
    const arr = [];

    for (const item of arrKeys) {
        const tmp = {
            value: InvoiceAccountingType[item],
            label: item,
            tkey: `invoice-accounting-type-${item}`
        };

        arr.push(tmp);
    }

    return arr;
}

function getDigitalSigningStatusDDL() {
    const arrKeys = Object.keys(SignicatSignStatus);
    const arr = [];

    for (const item of arrKeys) {
        const tmp = {
            value: SignicatSignStatus[item],
            label: item,
            tkey: `signicat-sign-status-${SignicatSignStatus[item]}`
        };

        arr.push(tmp);
    }

    return arr;
}

function getMarketGroupDDL() {
    const arrKeys = Object.keys(MarketGroup);
    const arr = [];

    for (const item of arrKeys) {
        const tmp = {
            value: MarketGroup[item],
            label: item,
            tkey: `market-group-${item}`
        };

        arr.push(tmp);
    }

    return arr;
}

function getToDoStatusDDL() {
    const arrKeys = Object.keys(ToDoStatus);
    const arr = [];

    for (const item of arrKeys) {
        const tmp = {
            value: ToDoStatus[item],
            label: item,
            tkey: `todo-status-${item}`
        };

        arr.push(tmp);
    }

    return arr;
}

function getToDoPriorityDDL() {
    const arrKeys = Object.keys(ToDoPriority);
    const arr = [];

    for (const item of arrKeys) {
        const tmp = {
            value: ToDoPriority[item],
            label: item,
            tkey: `todo-priority-${item}`
        };

        arr.push(tmp);
    }

    return arr;
}

function getShopPlatformDDL() {
    const arrKeys = Object.keys(ShopPlatform);
    const arr = [];

    for (const item of arrKeys) {
        const tmp = {
            value: ShopPlatform[item],
            label: item,
            tkey: `ShopPlatform-${item}`
        };

        arr.push(tmp);
    }

    return arr;
}

function getWidgetDDL(){
    const arrKeys = Object.keys(WidgetKeys);
    const arr = [];

    for (const item of arrKeys) {
        const tmp = {
            value: WidgetKeys[item],
            label: item,
            tkey: `db-widget-${item}`
        };

        arr.push(tmp);
    }

    return arr;
}