// https://stackoverflow.com/questions/18279141/javascript-string-encryption-and-decryption

import { Utils } from "./utils";

export const CryptUtils = {
    Encrypt,
    Decrypt
}

function Encrypt(text) {
    if (!text)
        return text

    text = str2CharCode(text)
    return text;

    // salt = salt || 'sf@2023!'
    // const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
    // const byteHex = (n) => ("0" + Number(n).toString(16)).substr(-2);
    // const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);

    // return text
    //     .split("")
    //     .map(textToChars)
    //     .map(applySaltToChar)
    //     .map(byteHex)
    //     .join("");
}

function Decrypt(encoded) {
    if (!encoded)
        return encoded

    return charCode2Str(encoded)

    // salt = salt || 'sf@2023!'
    // const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
    // const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);
    // return encoded
    //     .match(/.{1,2}/g)
    //     .map((hex) => parseInt(hex, 16))
    //     .map(applySaltToChar)
    //     .map((charCode) => String.fromCharCode(charCode))
    //     .join(""); 
}


function str2CharCode(text) {   
    let arr = Utils.str2CharCode(text)
    return JSON.stringify(arr)
}

function charCode2Str(jsonArr) {
    if (!jsonArr)
        return jsonArr

    let arr = JSON.parse(jsonArr)
    let text = Utils.charCode2Str(arr)

    return text
}