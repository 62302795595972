import { MenuPerms } from '../../enums/menu-perms'
import { PermKeys } from '../../enums/perm-keys'
import { PermissionUtil } from '../permission.util';

export const PermissionUtilDigitalSigning = {
    viewOnly,
    notAllowed
}

function viewOnly() {
    const perms = PermissionUtil.getPermission(true);

    if (perms && perms[MenuPerms.DigitalSignatureOption]) {
        const checkPerm = perms[MenuPerms.DigitalSignatureOption];

        return checkPerm[PermKeys.viewOnly];
    }

    return false;
}

function notAllowed() {
    const perms = PermissionUtil.getPermission(true);

    if (perms && perms[MenuPerms.DigitalSignatureOption]) {
        const checkPerm = perms[MenuPerms.DigitalSignatureOption];

        return checkPerm[PermKeys.notAllowed];
    }

    return true;
}
