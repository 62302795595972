export const state = () => {
  return {
    connection: null,
    startedPromise: null,
    manuallyClosed: false,
    started: false
  }
};

export const mutations = {
  updateConnection(state, connection) {
    state.connection = connection
  },
  updateStartedPromise(state, startedPromise) {
    state.startedPromise = startedPromise
  },
  updateManuallyClosed(state, manuallyClosed) {
    state.manuallyClosed = manuallyClosed
  },
  updateStarted(state, started) {
    state.started = started
  }
};

export const actions = {
  updateConnection({ commit }, connection) {
    commit('updateConnection', connection);
  },
  updateStartedPromise({ commit }, startedPromise) {
    commit('updateStartedPromise', startedPromise);
  },
  updateManuallyClosed({ commit }, manuallyClosed) {
    commit('updateManuallyClosed', manuallyClosed);
  },
  updateStarted({ commit }, started) {
    commit('updateStarted', started);
  }
};
