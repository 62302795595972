import { MenuPerms } from '../enums/menu-perms';
import { PermissionUtilCustomer } from './permission.util/permission.util.customer';
import { PermissionUtilProject } from './permission.util/permission.util.project';
import { PermissionUtilProjectTicket } from './permission.util/permission.util.project-ticket';
import { PermissionUtilEmailTicket } from './permission.util/permission.util.email-ticket';
import { PermissionUtilDigitalSigning } from './permission.util/permission.util.digital-signing';
import { CryptUtils } from './crypt.utils';
import { DefaultRole } from '../enums/default-role';
import { WidgetKeys } from '../enums/widget-keys';
import { EnumHelper } from "../helpers/enum.helper";

export const PermissionUtil = {
    initWidgetPermission,
    initWidgetPermissions,
    getWidgetPermission,

    initMenuPermission,
    allSupportMenu,
    initPermission,
    setPermission,
    getPermission,

    Customer: {
        allowAccess: PermissionUtilCustomer.allowAccess,
        allowView: PermissionUtilCustomer.allowView,
        allowEdit: PermissionUtilCustomer.allowEdit
    },

    Project: {
        allowEdit: PermissionUtilProject.allowEdit,
        allowViewAll: PermissionUtilProject.allowViewAll,
        allowViewOwned: PermissionUtilProject.allowViewOwned
    },

    Ticket: {
        allowEdit: PermissionUtilProjectTicket.allowEdit,
        allowViewAll: PermissionUtilProjectTicket.allowViewAll,
        allowViewOwned: PermissionUtilProjectTicket.allowViewOwned
    },

    EmailTicket: {
        allowViewAll: PermissionUtilEmailTicket.allowViewAll,
        allowViewAssigned: PermissionUtilEmailTicket.allowViewAssigned,
        allowEdit: PermissionUtilEmailTicket.allowEdit,
        allowViewUnassigned: PermissionUtilEmailTicket.allowViewUnassigned
    },

    DigitalSigning: {
        viewOnly: PermissionUtilDigitalSigning.viewOnly,
        notAllowed: PermissionUtilDigitalSigning.notAllowed
    }
}

function initMenuPermission(menuName, roleName) {
    if (menuName == MenuPerms.CustomerCardAndContactPersons) {
        if (roleName && roleName === DefaultRole.ADMINISTRATOR)
            return {
                canAdministrate: true, // Include add/edit           
                viewOnly: false,
                notAllowed: false
            };
        else if (roleName && roleName === DefaultRole.MANAGER)
            return {
                canAdministrate: false, // Include add/edit           
                viewOnly: true,
                notAllowed: false
            };
        else
            return {
                canAdministrate: false, // Include add/edit           
                viewOnly: false,
                notAllowed: true
            };
    }
    else if (menuName == MenuPerms.RelationshipManagementWithContactPersons) {
        if (roleName && roleName === DefaultRole.ADMINISTRATOR)
            return {
                canAdministrate: true, // Include add/edit      
                viewOnly: false,
                notAllowed: false
            };
        else if (roleName && roleName === DefaultRole.MANAGER)
            return {
                canAdministrate: false, // Include add/edit      
                viewOnly: true,
                notAllowed: false
            };
        else
            return {
                canAdministrate: false, // Include add/edit      
                viewOnly: false,
                notAllowed: true
            }
    }
    else if (menuName == MenuPerms.LoggingOfCommunications) {
        if (roleName && roleName === DefaultRole.ADMINISTRATOR)
            return {
                canAdministrate: true, // Include add/edit      
                viewOnly: false,
                notAllowed: false
            }
        else if (roleName && roleName === DefaultRole.MANAGER)
            return {
                canAdministrate: false, // Include add/edit      
                viewOnly: true,
                notAllowed: false
            }
        else
            return {
                canAdministrate: false, // Include add/edit      
                viewOnly: false,
                notAllowed: true
            }
    }
    else if (menuName == MenuPerms.Calendar) {
        if (roleName && roleName === DefaultRole.ADMINISTRATOR)
            return {
                manageEvent: true,
                viewOnly: false
            }
        else if (roleName && roleName === DefaultRole.MANAGER)
            return {
                manageEvent: true,
                viewOnly: false
            }
        else
            return {
                manageEvent: false,
                viewOnly: true
            }
    }
    else if (menuName == MenuPerms.GDPR) {
        if (roleName && roleName === DefaultRole.ADMINISTRATOR)
            return {
                viewOnly: true,
                notAllowed: false
            }
        else
            if (roleName && roleName === DefaultRole.MANAGER)
                return {
                    viewOnly: true,
                    notAllowed: false
                }
            else
                return {
                    viewOnly: false,
                    notAllowed: true
                }
    }
    else if (menuName == MenuPerms.EmployeeAbsenceManagement) {
        if (roleName && roleName === DefaultRole.ADMINISTRATOR)
            return {
                viewOnly: true,
                notAllowed: false
            }
        else if (roleName && roleName === DefaultRole.MANAGER)
            return {
                viewOnly: true,
                notAllowed: false
            }
        else
            return {
                viewOnly: false,
                notAllowed: true
            }
    }
    else if (menuName == MenuPerms.SalesLeadManagement) {
        if (roleName && roleName === DefaultRole.ADMINISTRATOR)
            return {
                viewOnly: true,
                notAllowed: false
            }
        else if (roleName && roleName === DefaultRole.MANAGER)
            return {
                viewOnly: true,
                notAllowed: false
            }
        else
            return {
                viewOnly: false,
                notAllowed: true
            }

    }
    else if (menuName == MenuPerms.APIAccess) {
        if (roleName && roleName === DefaultRole.ADMINISTRATOR)
            return {
                viewOnly: true,
                notAllowed: false
            }
        else if (roleName && roleName === DefaultRole.MANAGER)
            return {
                viewOnly: true,
                notAllowed: false
            }
        else
            return {
                viewOnly: false,
                notAllowed: true
            }
    }
    else if (menuName == MenuPerms.DigitalSignatureOption) {
        if (roleName && roleName === DefaultRole.ADMINISTRATOR)
            return {
                viewOnly: true,
                notAllowed: false
            }
        if (roleName && roleName === DefaultRole.MANAGER)
            return {
                viewOnly: true,
                notAllowed: false
            }
        else
            return {
                viewOnly: false,
                notAllowed: true
            }
    }
    else if (menuName == MenuPerms.ProjectManagementWithTicketFunction) {
        if (roleName && roleName === DefaultRole.ADMINISTRATOR)
            return {
                canSeeAllProjects: false,
                canSeeOnlyProjectsOwned: false,
                canAdministrate: true, // Include add/edit      
            }
        else if (roleName && roleName === DefaultRole.MANAGER)
            return {
                canSeeAllProjects: true,
                canSeeOnlyProjectsOwned: false,
                canAdministrate: false, // Include add/edit      
            }
        else
            return {
                canSeeAllProjects: true,
                canSeeOnlyProjectsOwned: false,
                canAdministrate: false, // Include add/edit      
            }
    }
    else if (menuName == MenuPerms.EmailTicketHandling) {
        if (roleName && roleName === DefaultRole.ADMINISTRATOR)
            return {
                canSeeAllEtickets: false,
                canSeeOnlyEticketsAssignedAndIsViewer: false,
                canEditEtickets: true,
                canSeeUnassignedEtickets: false
            }
        else if (roleName && roleName === DefaultRole.MANAGER)
            return {
                canSeeAllEtickets: true,
                canSeeOnlyEticketsAssignedAndIsViewer: false,
                canEditEtickets: false,
                canSeeUnassignedEtickets: false
            }
        else
            return {
                canSeeAllEtickets: true,
                canSeeOnlyEticketsAssignedAndIsViewer: false,
                canEditEtickets: false,
                canSeeUnassignedEtickets: false
            }
    }
}

function initWidgetPermission(widgetName, roleName) {
    if (widgetName == WidgetKeys.PtAssignedMe) {
        if (roleName && roleName === DefaultRole.ADMINISTRATOR)
            return {
                viewOnly: true,
                notAllowed: false
            };
        else if (roleName && roleName === DefaultRole.MANAGER)
            return {
                viewOnly: true,
                notAllowed: false
            };
        else
            return {
                viewOnly: true,
                notAllowed: false
            };
    }
    else if (widgetName == WidgetKeys.MyPtActivities) {
        if (roleName && roleName === DefaultRole.ADMINISTRATOR)
            return {
                viewOnly: true,
                notAllowed: false
            };
        else if (roleName && roleName === DefaultRole.MANAGER)
            return {
                viewOnly: true,
                notAllowed: false
            };
        else
            return {
                viewOnly: true,
                notAllowed: false
            }
    }
    else if (widgetName == WidgetKeys.PtActivityStream) {
        if (roleName && roleName === DefaultRole.ADMINISTRATOR)
            return {
                viewOnly: true,
                notAllowed: false
            }
        else if (roleName && roleName === DefaultRole.MANAGER)
            return {
                viewOnly: true,
                notAllowed: false
            }
        else
            return {
                viewOnly: true,
                notAllowed: false
            }
    }
    else if (widgetName == WidgetKeys.EtAssignedMe) {
        if (roleName && roleName === DefaultRole.ADMINISTRATOR)
            return {
                viewOnly: true,
                notAllowed: false
            }
        else if (roleName && roleName === DefaultRole.MANAGER)
            return {
                viewOnly: true,
                notAllowed: false
            }
        else
            return {
                viewOnly: true,
                notAllowed: false
            }
    }
    else if (widgetName == WidgetKeys.MyEtActivities) {
        if (roleName && roleName === DefaultRole.ADMINISTRATOR)
            return {
                viewOnly: true,
                notAllowed: false
            }
        else
            if (roleName && roleName === DefaultRole.MANAGER)
                return {
                    viewOnly: true,
                    notAllowed: false
                }
            else
                return {
                    viewOnly: true,
                    notAllowed: false
                }
    }
    else if (widgetName == WidgetKeys.UnassignedEt) {
        if (roleName && roleName === DefaultRole.ADMINISTRATOR)
            return {
                viewOnly: true,
                notAllowed: false
            }
        else if (roleName && roleName === DefaultRole.MANAGER)
            return {
                viewOnly: true,
                notAllowed: false
            }
        else
            return {
                viewOnly: true,
                notAllowed: false
            }
    }
    else if (widgetName == WidgetKeys.PtTicketChart) {
        if (roleName && roleName === DefaultRole.ADMINISTRATOR)
            return {
                viewOnly: true,
                notAllowed: false
            }
        else if (roleName && roleName === DefaultRole.MANAGER)
            return {
                viewOnly: true,
                notAllowed: false
            }
        else
            return {
                viewOnly: true,
                notAllowed: false
            }

    }
    else if (widgetName == WidgetKeys.EtTicketChart) {
        if (roleName && roleName === DefaultRole.ADMINISTRATOR)
            return {
                viewOnly: true,
                notAllowed: false
            }
        else if (roleName && roleName === DefaultRole.MANAGER)
            return {
                viewOnly: true,
                notAllowed: false
            }
        else
            return {
                viewOnly: true,
                notAllowed: false
            }
    }
    else if (widgetName == WidgetKeys.PTTicketClosedChart) {
        if (roleName && roleName === DefaultRole.ADMINISTRATOR)
            return {
                viewOnly: true,
                notAllowed: false
            }
        if (roleName && roleName === DefaultRole.MANAGER)
            return {
                viewOnly: true,
                notAllowed: false
            }
        else
            return {
                viewOnly: true,
                notAllowed: false
            }
    }
    else if (widgetName == WidgetKeys.ETTicketClosedChart) {
        if (roleName && roleName === DefaultRole.ADMINISTRATOR)
            return {
                viewOnly: true,
                notAllowed: false
            }
        else if (roleName && roleName === DefaultRole.MANAGER)
            return {
                viewOnly: true,
                notAllowed: false
            }
        else
            return {
                viewOnly: true,
                notAllowed: false
            }
    }
    else if (widgetName == WidgetKeys.SaleReport) {
        if (roleName && roleName === DefaultRole.ADMINISTRATOR)
            return {
                viewOnly: false,
                notAllowed: true
            }
        else if (roleName && roleName === DefaultRole.MANAGER)
            return {
                viewOnly: false,
                notAllowed: true
            }
        else
            return {
                viewOnly: false,
                notAllowed: true
            }
    }
    else if (widgetName == WidgetKeys.EventCalendar) {
        if (roleName && roleName === DefaultRole.ADMINISTRATOR)
            return {
                viewOnly: true,
                notAllowed: false
            }
        else if (roleName && roleName === DefaultRole.MANAGER)
            return {
                viewOnly: true,
                notAllowed: false
            }
        else
            return {
                viewOnly: true,
                notAllowed: false
            }
    }
    else if (widgetName == WidgetKeys.TODOList) {
        if (roleName && roleName === DefaultRole.ADMINISTRATOR)
            return {
                viewOnly: true,
                notAllowed: false
            }
        else if (roleName && roleName === DefaultRole.MANAGER)
            return {
                viewOnly: true,
                notAllowed: false
            }
        else
            return {
                viewOnly: true,
                notAllowed: false
            }
    }
}

function allSupportMenu() {

    // Platinum package
    return [
        MenuPerms.CustomerCardAndContactPersons,
        MenuPerms.RelationshipManagementWithContactPersons,
        MenuPerms.LoggingOfCommunications,
        MenuPerms.Calendar,
        MenuPerms.GDPR,
        MenuPerms.EmployeeAbsenceManagement,
        MenuPerms.SalesLeadManagement,
        MenuPerms.APIAccess,
        MenuPerms.DigitalSignatureOption,
        MenuPerms.ProjectManagementWithTicketFunction,
        MenuPerms.EmailTicketHandling
    ]
}

function initPermission() {
    const arrMenu = allSupportMenu()
    let obj = {}

    for (let menu of arrMenu) {
        obj[menu] = initMenuPermission(menu)
    }

    return obj
}

function initWidgetPermissions(roleName) {
    const arrWidgets = EnumHelper.getWidgetDDL();
    let obj = {}

    for (const widget of arrWidgets) {
        obj[widget.value] = initWidgetPermission(widget.value, roleName)
    }

    return obj
}

function setPermission(roles, encrypted) {
    let perm = initPermission()

    if (roles && roles.length > 0) {
        const arrMenu = allSupportMenu()

        // merged permission from all roles
        for (let role of roles) {

            if (role.isActive && role.isSelected && role.rolePermission && role.rolePermission != '') {
                var rolePerm = JSON.parse(role.rolePermission)

                for (let menu of arrMenu) {
                    const arrKeys = Object.keys(rolePerm[menu])
                    for (let key of arrKeys) {

                        if (key == 'notAllowed' && !rolePerm[menu][key])
                            perm[menu][key] = rolePerm[menu][key]

                        else if (rolePerm[menu][key])
                            perm[menu][key] = rolePerm[menu][key]
                    }
                }
            }
        }
    }

    // Set window.UserPerms
    if (encrypted === true) {
        const strPerm = JSON.stringify(perm);
        const encryptPerm = CryptUtils.Encrypt(strPerm);

        window.sfup = encryptPerm;
    }
    else
        window.sfup = perm;
}

function getPermission(decrypted) {
    // console.log('getPermission', new Date);

    // get window.UserPerms
    if (decrypted === true) {
        if (!window.sfup)
            location.href = "/logout";

        const strPerms = CryptUtils.Decrypt(window.sfup);
        const perms = JSON.parse(strPerms);
        return perms;
    }

    return window.sfup;
}


function getWidgetPermission(roles) {
    let perm = {};

    if (roles && roles.length > 0) {
        const lstWidgets = EnumHelper.getWidgetDDL();

        // merged widget permission from all roles
        for (const role of roles) {

            if (role.isActive && role.isSelected && role.widgetPermission) {
                var widgetPerm = JSON.parse(role.widgetPermission)

                for (const widget of lstWidgets) {
                    perm[widget.value] = perm[widget.value] || {
                        viewOnly: false,
                        notAllowed: false
                    };

                    const arrKeys = Object.keys(widgetPerm[widget.value])
                    for (let key of arrKeys) {

                        if (key == 'notAllowed' && !widgetPerm[widget.value][key])
                            perm[widget.value][key] = widgetPerm[widget.value][key]

                        else if (widgetPerm[widget.value][key])
                            perm[widget.value][key] = widgetPerm[widget.value][key]
                    }
                }
            }
        }
    }

    return perm;
}