
import { CacheKeys } from "../enums/cache-keys"
import { CryptUtils } from "../helpers/crypt.utils"

export const CacheUtils = {
    allowCacheImage,
    allowCacheData,

    clearImageCache,
    clearDataCache,
    clearPattern,
    clearAll,

    setCache,
    getCache
}

function allowCacheImage() {
    const allow = localStorage.getItem(CacheKeys.CacheImage) || '0'

    return allow == '1' || allow == 'true'
}

function allowCacheData() {
    const allow = localStorage.getItem(CacheKeys.CacheData) || '0'

    return allow == '1' || allow == 'true'
}

function clearImageCache() {
    clearPattern('img-')
}

function clearDataCache() {
    const arrKeys = Object.keys(CacheKeys)

    for (const key of arrKeys) {
        const value = CacheKeys[key]
        
        if (localStorage.getItem(value)){
            localStorage.removeItem(value)
        }
    }
}

function clearPattern(pattern) {
    for (const item of Object.keys(localStorage)) {
        // https://www.codeguage.com/courses/regexp/flags
        const regEx = new RegExp(pattern, 'gi')

        if (regEx.test(item))
        localStorage.removeItem(item)
    }
}

function clearAll() {
    clearDataCache()
    clearImageCache()
}

function setCache(key, obj, encryptData) {
    if (obj) {
        let data = JSON.stringify(obj)

        if (encryptData === true)
            data = CryptUtils.Encrypt(data)

        localStorage.setItem(key, data)
    }
}

function getCache(key, decryptData) {
    let data = localStorage.getItem(key)

    if (data) {
        if (decryptData === true)
            data = CryptUtils.Decrypt(data)

        return JSON.parse(data)
    }
}