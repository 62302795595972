export const MarketGroup = {
    Accounting: 1,
    DigitalSigning: 2,
    Task: 3
}

export const MarketProduct = {
    POGO: "pogo",
    Tripletex: "tripletex",
    Signicat: "signicat",
    TODOList: "todo_list"
}

export const MarketPaymentType = {
    DibsEasy: 1
}

// Active: (status & SubscriptionStatus.Active)  == SubscriptionStatus.Active
// Cancel: (status & SubscriptionStatus.Cancel)  == SubscriptionStatus.Cancel
export const SubscriptionStatus = {
    Pending: 0,
    Active: 1,
    Inactive: 2,
    Cancel: 4, // Canceled auto renewal
    ActiveDisabled: 5, // Active and Disabled auto renewal
    InactiveDisabled: 6,  // Inactive and Disabled auto renewal
}