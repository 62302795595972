

export const EventReminderType = {
    None: 0,
    Before5Min: 1,
    Before15Min: 2,
    Before30Min: 3,
    Before1Hour: 4,
    Before2Hour: 5,
    Before1Day: 6,
    Before2Days: 7,
    Before1Week: 8,
    Before2Weeks: 9
};