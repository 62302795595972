import moment from "moment";

export const DateUtils = {
    format,
    nowDate,
    getTimeZone,
    getTimeZoneInMinutes,
    addMininutes,
    addHours,
    addDays,
    addMonth,
    timeSince,
    dayOfWeek,
    diffDays,
    getLastDay
}

function format(date, format) {
    if (date)
        return moment(date).format(format || "DD.MM.YYYY");

    return "";
}

function getTimeZoneInMinutes() {
    const date = new Date()
    return date.getTimezoneOffset() * -1
}

function getTimeZone() {
    const date = new Date()
    return (date.getTimezoneOffset() * -1) / 60
}

function nowDate() {
    var nowDate = new Date();
    var date = nowDate.getFullYear() + '/' + (nowDate.getMonth() + 1) + '/' + nowDate.getDate();
    return date
}

function addMininutes(date, minutes) {
    if (date) {
        return new Date(date.setMinutes(date.getMinutes() + minutes))
    }

    return date
}

function addHours(date, hours) {
    if (date) {
        return new Date(date.setHours(date.getHours() + hours))
    }

    return date
}

function addDays(date, numDays) {
    if (date) {
        return new Date(date.setHours(date.getHours() + numDays * 24))
    }

    return date
}

function addMonth(date, month) {
    if (date) {
        return new Date(date.setMonth(date.getMonth() + month))
    }

    return date
}

function getLastDay(year, month){
    return new Date(year, month, 0).getDate();
}

function timeSince(date) {
    if (!date)
        return "";

    if (typeof date == "string")
        date = new Date(date);

    var seconds = Math.floor((new Date() - date) / 1000);
    var interval = seconds / 31536000;

    if (interval > 1) {
        return Math.floor(interval) + " years ago";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
        return Math.floor(interval) + " months ago";
    }
    interval = seconds / 86400;
    if (interval > 1) {
        return Math.floor(interval) + " days ago";
    }
    interval = seconds / 3600;
    if (interval > 1) {
        return Math.floor(interval) + " hours ago";
    }
    interval = seconds / 60;
    if (interval > 1) {
        return Math.floor(interval) + " minutes ago";
    }

    return Math.floor(seconds) + " seconds ago";
}

function dayOfWeek(date) {
    if (!date)
        return - 1;

    const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]

    let ret = -1;
    days.forEach((day, index) => {
        // Check if the index of day value is equal to the returned value of getDay()
        if (index == date.getDay()) {
            ret = index;
            return;
        }
    });

    return ret;
}

function diffDays(startDate, endDate) {
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const firstDate = new Date(startDate);
    const secondDate = new Date(endDate);

    const countDiffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
    return countDiffDays;
}