import { MenuPerms } from '../../enums/menu-perms'
import { PermKeys } from '../../enums/perm-keys'
import { PermissionUtil } from '../permission.util';

export const PermissionUtilCustomer = {
    allowAccess,
    allowView,
    allowEdit,
}

function allowAccess() {
    const perms = PermissionUtil.getPermission(true);

    if (perms && perms[MenuPerms.CustomerCardAndContactPersons]) {
        const checkPerm = perms[MenuPerms.CustomerCardAndContactPersons]
        const ret = checkPerm[PermKeys.notAllowed] == false || allowEdit()

        return ret
    }

    return false
}

function allowView() {
    const perms = PermissionUtil.getPermission(true);

    if (perms && perms[MenuPerms.CustomerCardAndContactPersons]) {
        const checkPerm = perms[MenuPerms.CustomerCardAndContactPersons]
        const ret = allowEdit() || checkPerm[PermKeys.viewOnly] == true || checkPerm[PermKeys.notAllowed] == false

        return ret
    }

    return false
}

function allowEdit() {
    const perms = PermissionUtil.getPermission(true);

    if (perms && perms[MenuPerms.CustomerCardAndContactPersons]) {
        const checkPerm = perms[MenuPerms.CustomerCardAndContactPersons]
        const ret = checkPerm[PermKeys.canAdministrate] == true

        return ret
    }

    return false
}