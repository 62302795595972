export const PermKeys = {
    canAdministrate: 'canAdministrate',
    viewOnly: 'viewOnly',
    notAllowed: 'notAllowed',

    manageEvent: 'manageEvent',

    canSeeAllProjects: 'canSeeAllProjects',
    canSeeOnlyProjectsOwned: 'canSeeOnlyProjectsOwned',

    canSeeAllEtickets: 'canSeeAllEtickets',
    canSeeOnlyEticketsAssignedAndIsViewer: 'canSeeOnlyEticketsAssignedAndIsViewer',
    canEditEtickets: 'canEditEtickets',
    canSeeUnassignedEtickets: 'canSeeUnassignedEtickets'
}