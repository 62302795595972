

export const RecurringType = {
    // [Display(Name : "Monthly")]
    Monthly: 1,

    // [Display(Name : "Every 2 months")]
    BiMonthly: 2,

    // [Display(Name : "Quarterly")]
    Quarterly: 3,

    // [Display(Name : "Every 4 months")]
    EveryFourMonths: 4,

    // [Display(Name : "Every 5 months")]
    EveryFifthMonths: 5,

    // [Display(Name : "Half yearly")]
    HalfYearly: 6,

    // [Display(Name : "Every 7 months")]
    EverySevenMonths: 7,

    // [Display(Name : "Every 8 months")]
    EveryEightMonths: 8,

    // [Display(Name : "Every 9 months")]
    EveryNineMonths: 9,

    // [Display(Name : "Every 10 months")]
    EveryTenMonths: 10,

    // [Display(Name : "Every 11 months")]
    EveryElevenMonths: 11,

    // [Display(Name : "Yearly")]
    Yearly: 12
}