

export const MenuPerms = {
    CustomerCardAndContactPersons: 'CustomerCardAndContactPersons',
    RelationshipManagementWithContactPersons: 'RelationshipManagementWithContactPersons',
    LoggingOfCommunications: 'LoggingOfCommunications',
    Calendar: 'Calendar',
    GDPR: 'GDPR',
    EmployeeAbsenceManagement: 'EmployeeAbsenceManagement',
    SalesLeadManagement: 'SalesLeadManagement',
    APIAccess: 'APIAccess',
    DigitalSignatureOption: 'DigitalSignatureOption',
    ProjectManagementWithTicketFunction: 'ProjectManagementWithTicketFunction',
    EmailTicketHandling: 'EmailTicketHandling'
}