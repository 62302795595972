import { createWebHistory, createRouter } from "vue-router";
import routes from './routes';
import redirects from './redirects';
import i18n from '@/i18n'
import { userService } from "@/services/user.service"
import { PermissionUtil } from "../helpers/permission.util"
import { MenuPerms } from "../enums/menu-perms";
import { clientService } from "@/services/client.service"

const router = createRouter({
  history: createWebHistory(), // createWebHistory(),  createWebHashHistory(),
  routes: routes.concat(redirects),
  // Use the HTML5 history API (i.e. normal-looking routes)
  // instead of routes with hashes (e.g. example.com/#/about).
  // This may require some server configuration in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  mode: 'history',
  // Simulate native-like scroll behavior when navigating to a new
  // route and using back/forward buttons.
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0, left: 0 };
    }
  },
});

// API based Authentication
// Before each route evaluates...
router.beforeEach(async (routeTo, routeFrom, next) => {

  const authRequired = routeTo.matched.some((route) => route.meta.authRequired);
  if (!authRequired)
    return next();

  // check user is admin (belong admin role) if access settings pages
  if (routeTo.path.indexOf("/settings/") >= 0 && routeTo.path.indexOf("settings/general-settings") == -1) {
    if (!userService.isAdmin())
      return next({ name: 'error-403' })
  }

  const ret = await userService.loadProfile();
  if (ret) {
    const menuPerm = routeTo.meta.menuPerm
    const checkAllowEdit = routeTo.meta.checkAllowEdit

    if (menuPerm == MenuPerms.CustomerCardAndContactPersons) {
      const allowAccess = PermissionUtil.Customer.allowAccess()
      if (!allowAccess)
        return next({ name: 'error-403' })
      else {
        if (checkAllowEdit && !PermissionUtil.Customer.allowEdit())
          return next({ name: 'error-403' })
      }
    }

    return next();
  }
  else
    if (routeTo.fullPath == '/')
      return next({ name: 'login' });
    else
      return next({ name: 'login', query: { url: routeTo.fullPath } });
});

router.beforeEach((routeTo, routeFrom, next) => {

  const publicPages = ['/login', '/auto-login', '/logout', '/forgot-password', '/auth/lockscreen',
    '/terms-of-service', '/privacy-policy', '/contact-us',
    '/reset-2fa-app', '/reintall-2fa-app'];
  let authpage = !publicPages.includes(routeTo.path);

  // double check authPage
  if (authpage && routeTo.path.indexOf('/user') >= 0) {
    const publicPages2 = ['/user/activate', '/user/invite', '/user/reset-pwd']
    for (let page in publicPages2) {
      if (routeTo.path.indexOf(page) >= 0) {
        authpage = false
        break
      }
    }
  }
  else if (authpage && routeTo.path.indexOf('/signicat/') >= 0) {
    authpage = false;
  }

  const loggeduser = userService.getUserinfo()
  const lockscreen = userService.getLockScreen()

  if (authpage && !loggeduser) {
    return next('/login');
  }
  else if (authpage && lockscreen == 1) {
    return next({ name: 'lockscreen', query: { url: routeTo.fullPath } });
  }

  next();
});

router.beforeResolve(async (routeTo, routeFrom, next) => {
  // Create a `beforeResolve` hook, which fires whenever
  // `beforeRouteEnter` and `beforeRouteUpdate` would. This
  // allows us to ensure data is fetched even when params change,
  // but the resolved route does not. We put it in `meta` to
  // indicate that it's a hook we created, rather than part of
  // Vue Router (yet?).
  try {
    // For each matched route...
    for (const route of routeTo.matched) {
      await new Promise((resolve, reject) => {
        // If a `beforeResolve` hook is defined, call it with
        // the same arguments as the `beforeEnter` hook.
        if (route.meta && route.meta.beforeResolve) {
          route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
            // If the user chose to redirect...
            if (args.length) {
              // If redirecting to the same route we're coming from...
              // Complete the redirect.
              next(...args);
              reject(new Error('Redirected'));
            } else {
              resolve();
            }
          });
        } else {
          // Otherwise, continue resolving the route.
          resolve();
        }
      });
    }
    // If a `beforeResolve` hook chose to redirect, just return.
  } catch (error) {
    return;
  }
  document.title = (routeTo.meta.tkey ? i18n.global.t(routeTo.meta.tkey) : routeTo.meta.title) + clientService.getSiteName()
  // If we reach this point, continue resolving the route.
  next();
});

export default router;
