export const UrlHelper = {
    rootUrl,
    resolveUrl,
    resolveApiUrl,
    resolvePhotoUrl,
    resolveFileUrl
}

function rootUrl() {
    return process.env.VUE_APP_URL || '';
}

function resolveUrl(url) {
    return `${rootUrl()}/${url}`;
}

function resolveApiUrl(url) {
    return `${rootUrl()}/api/${url}`;
}

function resolvePhotoUrl(photoUrl) {
    if (photoUrl && photoUrl.indexOf('http') >= 0)
        return photoUrl

    let url = `${rootUrl()}/${photoUrl}`
    return url
}

function resolveFileUrl(fileUrl) {
    if (fileUrl && fileUrl.indexOf('http') >= 0)
        return fileUrl

    return UrlHelper.resolvePhotoUrl(fileUrl)
}