

export const WidgetKeys = {
    PtAssignedMe: 'pt-assigned-me',
    MyPtActivities: 'my-pt-activities',
    PtActivityStream: 'pt-activity-stream',

    EtAssignedMe: 'et-assigned-me',
    MyEtActivities: 'my-et-activities',
    UnassignedEt: 'unassigned-et',

    PtTicketChart: 'pt-ticket-chart',
    EtTicketChart: 'et-ticket-chart',
    PTTicketClosedChart: 'pt-ticket-closed-chart',
    ETTicketClosedChart: 'et-ticket-closed-chart',

    SaleReport: 'sale-report',
    EventCalendar: 'event-calendar',

    TODOList: "todo-list"
}