

export const CacheKeys = {
    CountryList: "country_list",
    ClientList: 'client_list',
    ClientInfo: 'client_info',

    CustomerCategoryList: "customer_category",
    ProjectCategoryList: "project_category",

    TicketStatusList: "ticket_status",
    TicketTypeList: "ticket_type",
    TicketPriorityList: "ticket_priority",
    TicketResolutionList: "ticket_resolution",

    CacheImage: "cache_image",
    CacheData: "cache_data",

    PogoCachePrefix: "pogo",
    PogoProductList: "pogo_prodlist",
    PogoUnits: "pogo_units",
    PogoVatPrefix: "pogo_vat_",

    TripletexCachePrefix: "tripletex",
    TripletexPayments: "tripletex_payments",
    TripletexProducts: "tripletex_products",
    TripletexVat: "tripletex_vat",

    SiteSettings: "site_settings",
    HiddenSetting: "hidden_settings",

    UsersStatus: 'users_status',

    CShopCustomerImport: 'cshop_customer_import',

    EmailTicketSettings: "email_ticket_settings",
    ListActiveUser: "list_active_user",
    LoginUserInfo: "user_info",

    ListActiveAbsenceReason: "list_active_absence_reason",
}