import { createApp } from 'vue';
import App from './App.vue';
import router from "./router";
import AOS from 'aos';
import 'aos/dist/aos.css';
import i18n from './i18n';
import store from "./state/store";
import { JwtInterceptor } from './interceptors/jwt.interceptor'

import BootstrapVue3 from 'bootstrap-vue-3';
import vClickOutside from "click-outside-vue3";
import VueApexCharts from "vue3-apexcharts";
import Maska from 'maska';
import CustomEditor from "ckeditor5-custom-build/build/ckeditor"
import jQuery from "jquery"

window.$ = window.jQuery = jQuery
window.CustomEditor = CustomEditor

import '@/assets/scss/config/default/app.scss';
import '@vueform/slider/themes/default.css';
import '@/assets/scss/mermaid.min.css';

import { CacheUtils } from './helpers/cache.utils';

setInterval(() => {
    CacheUtils.clearDataCache()
}, 1000 * 60 * 15) // clear all localStorage every 15 minutes

AOS.init({
    easing: 'ease-out-back',
    duration: 1000
});

const app = createApp(App)
app.use(store)
    .use(router)
    .use(JwtInterceptor)
    .use(VueApexCharts)
    .use(BootstrapVue3)
    .use(Maska)
    .use(i18n)
    .use(vClickOutside)
    .mount('#app');