import { MenuPerms } from '../../enums/menu-perms'
import { PermKeys } from '../../enums/perm-keys'
import { PermissionUtil } from '../permission.util';

export const PermissionUtilEmailTicket = {
    allowViewAll,
    allowEdit,
    allowViewAssigned,
    allowViewUnassigned
}

/**
 * @param {string} menuPerms Menu name permission string
 */
function allowEdit() {
    const perms = PermissionUtil.getPermission(true);

    if (perms && perms[MenuPerms.EmailTicketHandling]) {
        const checkPerm = perms[MenuPerms.EmailTicketHandling]
        return checkPerm[PermKeys.canEditEtickets]
    }

    return false
}

function allowViewAll() {
    const perms = PermissionUtil.getPermission(true);

    if (perms && perms[MenuPerms.EmailTicketHandling]) {
        const checkPerm = perms[MenuPerms.EmailTicketHandling]
        return checkPerm[PermKeys.canSeeAllEtickets] || allowEdit()
    }

    return false
}

function allowViewAssigned() {
    const perms = PermissionUtil.getPermission(true);

    if (perms && perms[MenuPerms.EmailTicketHandling]) {
        const checkPerm = perms[MenuPerms.EmailTicketHandling]
        return checkPerm[PermKeys.canSeeOnlyEticketsAssignedAndIsViewer] || allowEdit()
    }

    return false
}

function allowViewUnassigned() {
    const perms = PermissionUtil.getPermission(true);

    if (perms && perms[MenuPerms.EmailTicketHandling]) {
        const checkPerm = perms[MenuPerms.EmailTicketHandling]
        return checkPerm[PermKeys.canSeeUnassignedEtickets] || allowEdit()
    }

    return false
}