

export const SettingKeys = {
    FIRST_INIT_DATA: 'first_init_data',
    SMTP: 'smtp',
    GENERAL: 'general',
    SF_CENTER_GENERAL: 'general',
    SMS: 'sms',
    POGO: 'pogo',
    TRIPLETEX: 'tripletex',
    SIGNICAT: 'signicat',
    EMAIL_JOB: 'email_job',
    EMAIL_TICKET_SETTINGS: 'email_ticket_settings', // include email ticket import job setting 
    CSHOP_CUSTOMER_IMPORT_SETTINGS: 'cshop_customer_import', // include cshop customer import job setting
    USER_SETTINGS: 'user_settings',    
    SF_CENTER_DIBS: "dibs",

    TEMPLATE_SEND_INVITE: 'template_send_invite',
    TEMPLATE_SEND_RESET_PASSWORD: 'template_send_reset_password',

    TEMPLATE_CREATE_TICKET: 'template_create_ticket',
    TEMPLATE_ASSIGN_TICKET: 'template_assign_ticket',
    TEMPLATE_COMMENT_TICKET: 'template_comment_ticket',
    TEMPLATE_SEND_ET_TO_CUSTOMER: 'template_send_et_to_customer',
    TEMPLATE_ASSIGN_PROJECT_TASK: 'template_assign_project_task',
    TEMPLATE_AUTO_REPLY_TO_CUSTOMER: 'template_auto_reply_to_customer',
    TEMPLATE_NEW_ABSENCE: 'template_new_absence',
    TEMPLATE_VERIFY_ABSENCE: 'template_verify_absence',

    HIDDEN: 'hidden_settings',
    GANTT_CHART_COLOR: 'gantt_chart_color',    

    PUBLIC_TERMS_OF_SERVICE: 'public_terms_of_service',
    PUBLIC_PRIVACY_POLICY: 'public_privacy_policy',
    PUBLIC_CONTACT_INFO: 'public_contact_info'
}