
export default [
  { path: '/customer/index', redirect: '/customers' },
  {
    path: '/customer/detail/:id',
    redirect: to => {
      return { path: `/customer/${to.params.id}` }
    }
  },

  { path: '/project/index', redirect: '/projects' },
  {
    path: '/project/detail/:id',
    redirect: to => {
      return { path: `/project/${to.params.id}` }
    }
  },

  {
    path: '/ticket/detail/:id',
    redirect: to => {
      console.log('to', to, 'params', to.params)
      return { path: `/ticket/${to.params.id}` }
    }
  },
  {
    path: '/ticket/index', redirect: to => {
      if (to.query.trash)
        return { path: `/tickets/trash`, query: {} }

      if (to.query.tt == 'ct')
        return { path: `/tickets/et`, query: {} }

      return { path: `/tickets/${to.query.tt}`, query: {} }
    }
  }

];