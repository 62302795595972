
export const ToDoStatus = {
    Pending: 0,
    Started: 1,
    Done: 2
};

export const ToDoPriority = {
    High: 0,
    Mid: 1,
    Low: 2
};