import { DateUtils } from "../../helpers/date.utils"

export const state = {
  interval: 0,
  nextLock: DateUtils.addMininutes(new Date, 5),
  hasValue: false
};

export const mutations = {
  updateInterval(state, interval) {
    state.interval = interval
  },
  updateNextLock(state, newDate) {
    state.nextLock = newDate
  },
  updateValue(state, value) {
    state.hasValue = value
  }
};

export const actions = {
  updateInterval({ commit }, interval) {
    commit('updateInterval', interval);
  },
  updateNextLock({ commit }, newDate) {
    commit('updateNextLock', newDate);
  },
  updateValue({ commit }, value) {
    commit('updateValue', value);
  }
};
